body {
  -webkit-font-smoothing: antialiased; /* For WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Pretendard" !important;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  background-color: black;
  line-height: 1;
  font-family: "Pretendard" !important;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#root {
  overflow: hidden;
}

::-webkit-scrollbar {
  background-color: black;
  opacity: 0.9;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(76, 76, 76, 0.541);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  margin: 10px;
}

::-webkit-scrollbar-button {
  background-color: rgb(96, 95, 95);
}

/* 


@font-face {
  font-family: "PretendardBold";
  font-display: swap;
  src: local("Pretendard ExtraBold"),
    url(./assest/fonts/Pretendard-Bold.ttf) format("ttf"),
    url(./assest/fonts/Pretendard-ExtraBold.ttf) format("ttf");
} */

@font-face {
  font-family: "Pretendard";
  font-display: swap;
  src: local("Pretendard Regular"),
    url(./assest/fonts/Pretendard-Regular.woff) format("woff2"),
    url(./assest/fonts/Pretendard-Regular.woff) format("woff");
}
@font-face {
  font-family: "PretendardBold";
  font-display: swap;
  src: url(./assest/fonts/Pretendard-Bold.woff) format("woff")
    url(./assest/fonts/Pretendard-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PretendardMedium";
  font-display: swap;
  src: local("Pretendard-Medium"),
    url(./assest/fonts/Pretendard-Medium.woff) format("woff")
      url(./assest/fonts/Pretendard-Medium.ttf) format("truetype");
}
